import Footer from '@/components/Footer';
import Head from '@/components/head';
import Header from '@/components/Header';
import clsx from 'clsx';
import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import { Fragment, useContext } from 'react';
import styles from './layout.module.scss';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { SettingsContext } from '@/utils/SettingsContext';

const MainLayout = ({
  page,
  children,
  title = '',
  header = true,
  footer = true,
  headerLogoLinked = true,
  paddings = true,
  breadcrumbs = [],
  instagram,
  customPaddings,
  footerClass,
}) => {
  const router = useRouter();
  const { t } = useTranslation();
  const settings = useContext(SettingsContext);
  const [showFooter, setShowFooter] = useState(footer);

  useEffect(() => {
    let timeout;
    if (!footer) {
      setShowFooter(false);
    } else {
      timeout = setTimeout(() => {
        setShowFooter(true);
      }, 500);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [footer, setShowFooter]);

  return (
    <>
      <Head meta_title={page?.meta_title} meta_description={page?.meta_description} title={title} />
      {header && (
        <Header
          headerLogoLinked={headerLogoLinked}
          showLanguageSwitcher={settings?.SHOW_LANGUAGE_SWITCHER}
        />
      )}
      {!!breadcrumbs.length && (
        <div className={styles.breadcrumbs}>
          {breadcrumbs.map((path, index) => (
            <Fragment key={path.route}>
              <Link href={path.route} locale={router.locale}>
                <span className={`${styles.breadcrumb} pointer`}>
                  {t(path.title).toLowerCase()}
                </span>
              </Link>
              {index < breadcrumbs.length - 1 && <span> / </span>}
            </Fragment>
          ))}
        </div>
      )}
      <div
        className={clsx(styles.main, {
          [styles.paddings]: paddings,
          [customPaddings]: !!customPaddings,
        })}
      >
        <AnimatePresence exitBeforeEnter>
          <Fragment key={`${router.route}${router.query.id ? router.query.id : ''}`}>
            {children}
          </Fragment>
        </AnimatePresence>
      </div>
      {showFooter && (
        <Footer
          className={clsx({
            [footerClass]: !!footerClass,
          })}
          instagram_visible={settings?.INSTAGRAM_LINK_VISIBLE}
          instagram={settings?.INSTAGRAM_LINK}
        />
      )}
    </>
  );
};

export default MainLayout;
