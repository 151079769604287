import React, { useState, useCallback, useEffect } from 'react';
import debounce from 'lodash.debounce';
import styles from './search.module.scss';
import Link from 'next/link';
import API from '../../../api';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const SearchInput = ({ onClickFound, onOpen, onClose, opened }) => {
  const { t } = useTranslation(['common', 'menu']);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const { locale } = useRouter();

  const getText = useCallback(
    value => (locale === 'ru' ? value.text : value[`text_${locale}`]),
    [locale]
  );

  const getSuggestions = useCallback(
    word => {
      if (word) {
        onOpen();
        setLoading(true);
        API.get(`/v1/api/search?q=${word}`)
          .then(response => {
            setOptions(response.data);
          })
          .catch(error => {
            console.error(error);
            onClose();
          })
          .finally(() => setLoading(false));
      } else {
        setOptions([]);
      }
    },
    [setOptions, setLoading, onOpen, onClose]
  );
  const debouncedSave = useCallback(debounce(getSuggestions, 1000), [getSuggestions]);

  useEffect(() => {
    const listener = event => {
      if (
        !event.target.classList.contains(`.${styles.searchInputWrapper}`) &&
        !event.target.closest(`.${styles.searchInputWrapper}`)
      ) {
        onClose();
      }
    };
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, [onClose]);

  return (
    <div className={clsx(styles.searchInputWrapper, { [styles.opened]: opened })}>
      <input
        className={styles.input}
        value={inputValue}
        onChange={input => {
          setInputValue(input.target.value);
          debouncedSave(input.target.value);
        }}
        onFocus={() => {
          if (!opened && !!options.length) {
            onOpen();
          }
        }}
        //onBlur={() => setTimeout(onClose)}
      />
      <svg
        className={styles.close_icon}
        onClick={() => {
          setOptions([]);
          setInputValue('');
          onClose();
        }}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.192 0.344238L5.94897 4.58624L1.70697 0.344238L0.292969 1.75824L4.53497 6.00024L0.292969 10.2422L1.70697 11.6562L5.94897 7.41424L10.192 11.6562L11.606 10.2422L7.36397 6.00024L11.606 1.75824L10.192 0.344238Z"
          fill="white"
        />
      </svg>

      <svg
        className={styles.search_icon}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.023 14.977C16.5546 14.5284 16.0988 14.0669 15.656 13.593C15.284 13.215 15.06 12.94 15.06 12.94L12.26 11.603C13.381 10.3316 13.9997 8.69499 14 7C14 3.141 10.86 0 7 0C3.14 0 0 3.141 0 7C0 10.859 3.14 14 7 14C8.763 14 10.37 13.34 11.603 12.261L12.94 15.061C12.94 15.061 13.215 15.285 13.593 15.657C13.98 16.02 14.489 16.511 14.977 17.024L16.335 18.416L16.939 19.062L19.06 16.941L18.414 16.337C18.035 15.965 17.529 15.471 17.023 14.977ZM7 12C4.243 12 2 9.757 2 7C2 4.243 4.243 2 7 2C9.757 2 12 4.243 12 7C12 9.757 9.757 12 7 12Z"
          fill="white"
        />
      </svg>
      {opened && (
        <div className={styles.suggestContainer}>
          <ul className={styles.list}>
            {loading && <li className={styles.listItem}>{t('Поиск...')}</li>}
            {!loading && !!inputValue.length && !options.length && (
              <li className={styles.listItem}>{t('Ничего не найдено')}</li>
            )}
            {options?.map(value => (
              <li
                onClick={() => {
                  setInputValue('');
                  setOptions([]);
                  onClickFound(value.url);
                }}
                className={styles.listItem}
                key={value.url}
              >
                <Link href={value.url}>
                  <a title={getText(value)}>
                    <img src={value.icon} alt={getText(value)} /> <span>{getText(value)}</span>
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchInput;
