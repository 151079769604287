import { motion } from 'framer-motion';
import clsx from 'clsx';
import styles from './mheader.module.scss';

const headerVariants = {
  exit: {
    opacity: 0,
    y: '-15%',
    transition: { duration: 0.3 },
  },
  initial: {
    overflow: 'hidden',
    width: '0%',
    whiteSpace: 'nowrap',
  },
  enter: {
    width: '100%',
    transition: { duration: 2, delay: 0.3 },
  },
};

const MotionHeader = ({ children, className }) => {
  return (
    <motion.h1
      className={clsx(styles.header, { [className]: !!className })}
      variants={headerVariants}
      initial="initial"
      animate="enter"
      exit="exit"
    >
      {children}
    </motion.h1>
  );
};

export default MotionHeader;
