import Logo from '@/components/Logo';
import { Container } from '@/components/ui/Grid';
import Link from 'next/link';
import styles from './header.module.scss';
import Menu from './Menu';
import { motion } from 'framer-motion';

const Header = ({ headerLogoLinked = true, showLanguageSwitcher }) => {
  return (
    <motion.div
      className={styles.wrapper}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { duration: 0.5, delay: 0.5 },
      }}
    >
      <Container>
        <div className={styles.inner}>
          {headerLogoLinked ? (
            <Link passHref href="/">
              <a className={styles.logo}>
                <Logo />
              </a>
            </Link>
          ) : (
            <a className={styles.logo}>
              <Logo />
            </a>
          )}
          <Menu showLanguageSwitcher={showLanguageSwitcher} />
        </div>
      </Container>
    </motion.div>
  );
};

export default Header;
