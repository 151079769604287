import Logo from '@/components/Logo';
import { Container } from '@/components/ui/Grid';
import { motion } from 'framer-motion';
import styles from './footer.module.scss';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

const Footer = ({ instagram, instagram_visible }) => {
  const { t } = useTranslation(['common', 'footer']);
  const currentDate = new Date();
  return (
    <motion.div
      className={styles.wrapper}
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
        transition: { duration: 1.3, delay: 0.7 },
      }}
    >
      <Container>
        <div className={styles.first}>
          <div className={styles.left}>
            <div className={styles.logo}>
              <Logo />
            </div>
            <div className={styles.slogan}>
              {t('Создано морем')}
              <br />
              {t('Доставлено ЮНИФРОСТ')}
            </div>
          </div>
          <div className={styles.links}>
            <nav>
              <ul>
                <li>
                  <Link href="/about">
                    <a>{t('О компании')}</a>
                  </Link>
                </li>
                <li>
                  <Link href="/documents">
                    <a>{t('Документы')}</a>
                  </Link>
                </li>
                <li>
                  <Link href="/feedback">
                    <a>{t('Связаться с нами')}</a>
                  </Link>
                </li>
              </ul>
            </nav>
            <nav>
              <ul>
                <li>
                  <Link href="/contacts">
                    <a>{t('Контакты')}</a>
                  </Link>
                </li>
                <li>
                  <Link href="/vacancies">
                    <a>{t('Вакансии')}</a>
                  </Link>
                </li>
                <li>
                  {instagram_visible && (
                    <a href={instagram}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.94688 0H16.3722C20.2027 0 23.319 3.1052 23.3191 6.92201V16.3136C23.3191 20.1304 20.2027 23.2356 16.3722 23.2356H6.94688C3.11636 23.2356 0 20.1304 0 16.3136V6.92201C0 3.1052 3.11636 0 6.94688 0ZM16.3722 20.8993C18.9132 20.8993 20.9731 18.8467 20.9731 16.3148V6.92324C20.9731 4.39127 18.9132 2.33875 16.3722 2.33875H6.94691C4.40589 2.33875 2.34595 4.39127 2.34595 6.92324V16.3148C2.34595 18.8467 4.40589 20.8993 6.94691 20.8993H16.3722ZM5.55212 11.8941C5.55212 8.69124 8.16736 6.08545 11.3819 6.08545C14.5964 6.08545 17.2117 8.6912 17.2117 11.8941C17.2117 15.0971 14.5964 17.7029 11.3819 17.7029C8.16736 17.7029 5.55212 15.097 5.55212 11.8941ZM7.8197 11.8956C7.8197 13.8559 9.41454 15.4449 11.3819 15.4449C13.3492 15.4449 14.9441 13.8558 14.9441 11.8956C14.9441 9.93538 13.3493 8.34631 11.3819 8.34631C9.41458 8.34631 7.8197 9.93538 7.8197 11.8956ZM17.4893 6.64246C18.2559 6.64246 18.8773 6.02289 18.8773 5.25861C18.8773 4.49433 18.2559 3.87476 17.4893 3.87476C16.7227 3.87476 16.1012 4.49433 16.1012 5.25861C16.1012 6.02289 16.7227 6.64246 17.4893 6.64246Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className={styles.copyright}>
          &copy; {currentDate.getFullYear()} UNIFROST.{' '}
          <Link passHref href="/privacy-policy">
            <a>{t('Политика конфиденциальности')}</a>
          </Link>
        </div>
      </Container>
    </motion.div>
  );
};

export default Footer;
