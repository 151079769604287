import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styles from './menu.module.scss';
import { useEffect, useMemo, useState } from 'react';
import SearchInput from '@/components/Header/SearchInput/SearchInput';
import { useTranslation } from 'next-i18next';

const Item = ({ link, asPath, setOpened }) => {
  const active = asPath.includes(link.href);

  return (
    <li>
      <Link passHref href={link.href}>
        <a
          onClick={() => setOpened(false)}
          className={clsx(styles.link, { [styles.active]: active })}
        >
          {link.anchor}
        </a>
      </Link>
    </li>
  );
};

const Menu = ({ showLanguageSwitcher }) => {
  const { t } = useTranslation('menu');
  const [opened, setOpened] = useState(false);
  const [searchOpened, setSearchOpened] = useState(false);

  const menu = useMemo(
    () => [
      { id: 2, anchor: t('О компании'), href: '/about' },
      { id: 4, anchor: t('География закупок'), href: '/geo' },
      { id: 3, anchor: t('Продукция'), href: '/catalog' },
      { id: 5, anchor: t('Обратная связь'), href: '/feedback' },
      { id: 6, anchor: t('Запросить прайс'), href: '/request-price' },
      { id: 7, anchor: t('Контакты'), href: '/contacts' },
    ],
    [t]
  );

  useEffect(() => {
    if (opened) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = null;
    }
  }, [opened]);

  const { asPath } = useRouter();

  return (
    <>
      {showLanguageSwitcher && (
        <div className={styles.langSwitcherMobile}>
          <Link href={asPath} locale={'ru'}>
            RU
          </Link>{' '}
          /{' '}
          <Link href={asPath} locale={'en'}>
            EN
          </Link>
        </div>
      )}
      {!searchOpened && (
        <div
          className={clsx(styles.burger_container, { [styles.burger_opened]: opened })}
          onClick={() => setOpened(!opened)}
        >
          <div className={styles.burger_wrapper}>
            <div className={clsx(styles.bar, styles.topBar)} />
            <div className={clsx(styles.bar, styles.btmBar)} />
          </div>
        </div>
      )}
      <nav className={clsx(styles.navigation, { [styles.nav_opened]: opened })}>
        <ul>
          {menu.map(link => (
            <Item key={link.id} link={link} asPath={asPath} setOpened={setOpened} />
          ))}
          <li className={styles.searchContainer}>
            <SearchInput
              onOpen={() => setSearchOpened(true)}
              onClose={() => setSearchOpened(false)}
              opened={searchOpened}
              onClickFound={() => {
                if (opened) {
                  setOpened(false);
                }
              }}
            />
            {showLanguageSwitcher && (
              <div className={styles.langSwitcherDesktop}>
                <Link href={asPath} locale={'ru'}>
                  RU
                </Link>{' '}
                /{' '}
                <Link href={asPath} locale={'en'}>
                  EN
                </Link>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Menu;
